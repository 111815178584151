<template>
  <b-modal
    v-model="isShow"
    id="modal-action"
    modal-class="modal-primary"
    title="Rejected Change Status"
    title-tag="h3"
    header-bg-variant="danger"
    @hidden="close"
    centered
  >
    <template #default>
      <b-container>
        <b-row>
          <b-col>
            <b-form-group label="Program:">
              <b-form-input
                class="rounded text-primary border-primary font-medium-1 text-center py-1s"
                v-model="program"
                disabled 
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Client:">
              <b-form-input
                class="rounded text-primary border-primary font-medium-1 text-center py-1s"
                v-model="client"
                disabled 
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Motive:">
              <b-form-textarea v-model="motive" cols="3" no-resize />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template #modal-footer>
      <b-button variant="danger" @click="sendData">
        REJECTED
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import RequestClientStatusServices from "@/views/management/views/request-client-status/services/RequestClientStatus.services.js"; 
import { mapGetters } from "vuex";
export default {
  props: {
    idAccount: { required: true, type: String },
    program: { required: true, type: String },
    client: { required: true, type: String },
  },
  data() {
    return {
      isShow:false,
      motive: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async sendData(){ 
        try{
            const response = await this.showConfirmSwal(
                "Are you sure?",
                "You won't be able to revert this!"
            );
            if(response.isConfirmed){
                const params = { 
                    id: this.idAccount,
                    user_id: this.currentUser.user_id,    
                    motive: this.motive, 
                }
                this.addPreloader();
                const data = await RequestClientStatusServices.ChangeStatusRejected(params)
                if (data.status == 200) {
                    this.showSuccessSwal('Success', 'Status has been changed') 
                    this.close();
                }
            }
            
        }catch(error){
                this.showErrorSwal(error);
        } finally {
            this.removePreloader();
        }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() { 
    this.isShow = true;
  },
};
</script>
