<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="resetSearch"
    >
      <b-table
        id="refRequestClientStatus"
        slot="table"
        ref="refRequestClientStatus"
        show-empty
        no-provider-filtering
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :items="myProvider"
        :fields="getVisibleFields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
        :filter="searchInput"
        :no-sort-reset="true"
        table-class="text-nowrap"
        no-border-collapse
        sticky-header="50vh"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(client_name)="data">
          <div class="d-flex flex-column">
            <router-link
              target="_blank"
              :to="{
                name: clientDashboardRouteName(),
                params: {
                  idClient: `${data.item.id_account}`,
                },
              }"
            >
              {{ data.item.client_name }}
            </router-link>
            <span>{{ data.item.account }}</span>
          </div>
        </template>

        <template #cell(user_name)="data">
          <div class="d-flex flex-column">
            <span>{{ data.item.user_name }}</span>
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </div>
        </template>

        <template #cell(old_client_account_status)="data">
          <div>
            <status-account
              :account="JSON.parse(data.item.old_client_account_status)[0]"
              :optional-id="data.item.program_id"
              :text="true"
            ></status-account>
          </div>
        </template>

        <template #cell(new_client_account_status)="data">
          <div>
            <status-account
              :account="JSON.parse(data.item.new_client_account_status)[0]"
              :optional-id="data.item.program_id"
              :text="true"
            ></status-account>
          </div>
        </template>

        <template #cell(request_motive)="data">
          <div style="white-space: break-spaces">
            <p v-if="data.item.request_motive.length >= 50 && isShowMore">
              {{ data.item.request_motive | limitChars(50) }}
            </p>
            <p v-else>{{ data.item.request_motive }}</p>
            <b-button
              v-if="data.item.request_motive.length >= 50"
              variant="flat-info"
              size="sm"
              class="text-nowrap"
              @click="isShowMore = !isShowMore"
              >{{ !isShowMore ? "Show Less" : "Show More" }}
            </b-button>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex justify-content-center" style="margin-top: 8px">
            <b-button
              variant="primary"
              pill
              size="sm"
              @click="changeStatus(data.item, 2)"
              >Approve</b-button
            >
            <b-button
              variant="danger"
              pill
              size="sm"
              class="ml-1"
              @click="changeStatus(data.item, 3)"
              >Dissaprove</b-button
            >
          </div>
        </template>
      </b-table>
    </filter-slot>
    <motive-rejected-modal
      v-if="modalRejected"
      :id-account="id_account"
      :program="program"
      :client="client"
      @close="modalRejected = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Fields from "@/views/management/views/request-client-status/components/fields.data.js";
import RequestClientStatusServices from "@/views/management/views/request-client-status/services/RequestClientStatus.services.js";
import motiveRejectedModal from "@/views/management/views/request-client-status/modals/motiveRejectedModal.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: { motiveRejectedModal, StatusAccount },
  data() {
    return {
      filters: [],
      sourcesname_id: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by account, client or advisor",
        model: "",
      },
      fields: Fields,
      totalRows: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "name",
      sortDesc: true,
      searchInput: "",
      showModalSalary: false,
      salaryRecord: null,
      showModalTrackingSalary: false,
      showModalObservation: false,
      showModalAdjustSalary: false,
      showModalShowInfo: false,
      isShowMore: true,
      modalRejected: false,
      program: null,
      client: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    currentModuleId() {
      return this.currentUser.modul_id;
    },
    getTypeStatus() {
      return this.$route.matched[2].meta.type;
    },
    getVisibleFields() {
      if (this.getTypeStatus == 1 && [16, 18].includes(this.currentModuleId)) {
        this.fields[7].visible = true;
        this.fields[2].visible = false;
      } else {
        this.fields[2].visible = true;
        this.fields[7].visible = false;
      }
      if (this.getTypeStatus == 2) {
        this.fields[1].visible = true;
        this.fields[5].visible = false;
      } else {
        this.fields[1].visible = false;
        this.fields[5].visible = true;
      }

      return this.fields.filter((item) => item.visible);
    },
  },
  async created() {
    await this.destroySocket();
    await this.activeSocketChangeStatus();
  },
  async destroyed() {
    await this.destroySocket();
  },
  methods: {
    async myProvider(ctx) {
      const params = {
        page: ctx.currentPage,
        per_page: ctx.perPage,
        status: this.getTypeStatus,
        from: null,
        to: null,
        name_text: this.filterPrincipal.model,
        module_id: this.currentModuleId,
      };
      this.addPreloader();
      try {
        const { data } =
          await RequestClientStatusServices.getRequestClientStatus(params);
        const items = data.data;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return items || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    resetSearch() {
      this.$refs.refRequestClientStatus.refresh();
    },
    async changeStatus(item, type) {
      if (type == 2) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          const params = {
            status: item.id_new_status,
            id: item.id_account,
            session_id: this.currentUser.user_id,
            subject: item.client_name + " | " + item.account,
            content: item.request_motive,
            advisorid: item.created_by,
            idprogram: item.program_id,
          };
          this.addPreloader();
          try {
            const data = await RequestClientStatusServices.changeStatus(params);
            if (data.status == 200) {
              this.spinner = false;
              this.showSuccessSwal("Success", "Status has been changed");
              this.resetSearch();
            }
          } catch (error) {
            this.showErrorSwal(error);
          } finally {
            this.removePreloader();
          }
        }
      } else if (type == 3) {
        this.program = item.name_program;
        this.client = item.client_name;
        this.id_account = item.id_account;
        this.modalRejected = true;
      }
    },

    activeSocketChangeStatus() {
      try {
        window.socket.subscribe("channel-chat");
        window.socket.bind("change-status-client-refresh", async (response) => {
          const data =
            await RequestClientStatusServices.getCountPendingChangeStatus({
              module_id: this.currentModuleId,
            });
          const payload = {
            routeName: this.$route.matched[1].name,
            tag: data.data,
          };
          this.$store.commit(
            "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
            payload
          );
          this.resetSearch();
        });
      } catch (error) {
        console.log("Socket-binnacle: ", error);
      }
    },
    async destroySocket() {
      return new Promise((resolve, reject) => {
        try {
          window.socket.unbind("change-status-client-refresh");
          resolve(true);
        } catch (error) {
          reject(false);
          console.log(error);
        }
      });
    },
    clientDashboardRouteName() {
      const routers = {
        2: "dashboard-crm",
        3: "bussiness-dashboard-clients",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        18: "quality-client-dashboard",
        20: "connection-clients-account",
        22: "ce-customer-service-dashboard",
        25: "specialist-digital-dashboard",
        16: "management-client-dashboard",
      };
      return routers[this.currentModuleId] || null;
    },
  },
};
</script>
<style scoped>
.btn-status {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
