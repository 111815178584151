export default [
    {
        key: 'client_name',
        sortable: false,
        label: 'Client Name',
        visible: true,
        thClass: "text-center",
        tdClass: 'text-center'
    },
    {
        key: 'rejected_motive',
        sortable: false,
        label: 'Rejected Motive ',
        visible: false,
        thClass: "text-center",
        tdClass: 'text-center'
    },
    {
        key: 'advisor_name',
        sortable: false,
        label: 'Advisor',
        visible: false,
        thClass: "text-center",
        tdClass: 'text-center'
    },
    {
        key: 'old_client_account_status',
        sortable: false,
        label: 'Current Status',
        visible: true,
        thClass: "text-center",
        tdClass: 'text-center'
    },
    {
        key: 'new_client_account_status',
        sortable: false,
        label: 'New Status',
        visible: true,
        thClass: "text-center",
        tdClass: 'text-center'
    },
    {
        key: 'request_motive',
        sortable: false,
        label: 'Request Motive ',
        visible: true,
        thClass: "text-center",
        tdClass: 'text-center'
    },
    {
        key: 'user_name',
        sortable: false,
        label: 'Requested By',
        visible: true,
        thClass: "text-center",
        tdClass: 'text-center'
    },
    {
        key: 'actions',
        sortable: false,
        label: 'Actions',
        visible: false,
        thClass: "text-center",
        tdClass: 'text-center'
    },
];
